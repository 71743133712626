<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="staff-form v-card v-sheet theme--light"
    >
      <div
        v-if="isEditMode && !profileView"
        class="d-flex justify-center align-center py-2"
      >
        <v-img
          max-width="36"
          width="36"
          height="36"
          :src="defaultAvatar"
          class="mr-2"
        ></v-img>
        <h2>{{ fullName }}</h2>
      </div>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab
          v-for="(step, index) in steps"
          :key="index"
          class="rounded"
          active-class="secondary"
        >
          <v-icon left>{{ `mdi-${step.icon}` }}</v-icon> {{ step.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!-- OVERVIEW -->
        <v-tab-item>
          <v-container class="d-flex">
            <v-col cols="12" sm="4" class="pb-0">
              <v-text-field
                filled
                shaped
                readonly
                label="Name"
                v-model="fullName"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Preferred Name"
                v-model="userData.preferredName"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Pronouns"
                v-model="userData.pronouns"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Location"
                v-model="userData.country"
              />
              <v-text-field
                filled
                shaped
                readonly
                label="Start Date"
                v-model="userData.startDate"
              ></v-text-field>
            </v-col>
            <v-divider vertical></v-divider>
            <v-card
              :class="`d-flex align-center ${
                profileView ? 'justify-space-around' : ''
              } pa-2 mx-4`"
              width="65%"
              shaped
            >
              <div
                class="staff-form__profile-chips-container d-flex flex-column align-center justify-center"
              >
                <v-chip
                  v-if="userData.specialty"
                  x-large
                  label
                  class="staff-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="yellow lighten-3"
                  text-color="black"
                >
                  {{ userData.specialty }}
                </v-chip>
                <v-chip
                  v-if="userData.rate"
                  x-large
                  label
                  class="staff-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="green lighten-2"
                  text-color="white"
                >
                  {{ `${userData.rate}$ / hour` }}
                </v-chip>
                <v-chip
                  v-if="userData.availability"
                  x-large
                  label
                  class="staff-form__profile-chip overline text-center py-3 my-2 elevation-3"
                  color="blue lighten-2"
                  text-color="white"
                >
                  {{ userData.availability }}
                </v-chip>
              </div>
              <div
                class="staff-form__profile-avatar-container d-flex flex-column align-center"
              >
                <v-skeleton-loader
                  v-if="userData.photo ? photoLoading : false"
                  width="350px"
                  type="image"
                ></v-skeleton-loader>
                <v-img
                  v-else
                  width="350"
                  height="350"
                  class="rounded-lg"
                  :src="itemToEdit?.photoRef ? avatar : defaultAvatar"
                  alt="Profile photo"
                ></v-img>
                <div
                  v-if="!profileView"
                  class="staff-form__profile-avatar-info d-flex align-center pt-4 px-4"
                >
                  <div class="d-flex align-center mr-auto">
                    <span class="text-h5">Active:</span>
                    <v-icon v-if="itemToEdit?.endDate" x-large color="error"
                      >mdi-close-box-outline</v-icon
                    >
                    <v-icon
                      v-else
                      x-large
                      color="success"
                      :disabled="!isEditMode"
                      >mdi-checkbox-marked-outline</v-icon
                    >
                  </div>
                </div>
              </div>
              <v-card-actions
                v-if="!profileView"
                class="staff-form__profile-actions-container flex-column align-start pa-0"
              >
                <v-btn
                  x-large
                  text
                  class="slack-btn ml-0"
                  :href="`slack://user?team=T04U84EML4X&id=${userData.slackId}`"
                  :disabled="!userData.slackId"
                >
                  <v-icon large color="accent" class="mr-1"> mdi-slack </v-icon
                  >DM</v-btn
                >
                <v-btn
                  icon
                  x-large
                  class="ml-0"
                  color="accent"
                  :disabled="!userData.email"
                  @click="sendEmail(userData.email)"
                >
                  <v-icon>mdi-email-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-tab-item>
        <!-- PERSONAL DETAILS -->
        <v-tab-item eager>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="First Name"
                v-model="userData.firstName"
                :rules="[rules.required]"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Last Name"
                v-model="userData.lastName"
                :rules="[rules.required]"
              />
              <v-row class="mt-0">
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Preferred Name"
                    v-model="userData.preferredName"
                    :rules="isAdmin ? [] : [rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-menu
                    v-model="bDayMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="userData.bday"
                        :rules="isAdmin ? [] : [rules.required]"
                        :class="{ disabledPicker: isViewMode }"
                        label="Birthday"
                        prepend-icon="mdi-calendar-star"
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="bDayMenu = false"
                      v-model="userData.bday"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Legal Full Name (in native language)"
                v-model="userData.legalName"
                :rules="isAdmin ? [] : [rules.required]"
              />
            </v-col>
            <v-divider inset vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0 text-center">
              <v-row class="mt-0">
                <v-col cols="12" sm="6" class="py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="English Level"
                    :items="engLevelOptions"
                    v-model="userData.engLevel"
                    :rules="[rules.required]"
                  ></v-autocomplete
                ></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-combobox
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Pronouns"
                    :items="pronounsList"
                    v-model="userData.pronouns"
                    :rules="isAdmin ? [] : [rules.required]"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="LinkedIn"
                    :clearable="!isViewMode"
                    v-model="userData.linkedIn"
                /></v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Website"
                    :clearable="!isViewMode"
                    v-model="userData.website"
                /></v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" sm="6" class="pb-0">
                  <v-file-input
                    v-if="!isViewMode"
                    outlined
                    v-model="photoBlob"
                    accept=".png, .jpg, .jpeg"
                    truncate-length="20"
                    label="Photo"
                    :loading="itemToEdit?.photoRef ? photoLoading : false"
                    :disabled="itemToEdit?.photoRef ? photoLoading : false"
                    @change="processFileRef($event, 'photo')"
                    :rules="
                      isAdmin
                        ? [rules.isImage]
                        : [rules.requiredFile, rules.isImage]
                    "
                  />
                  <v-btn
                    v-else
                    x-large
                    width="100%"
                    height="58px"
                    color="accent"
                    class="mb-7"
                    :disabled="!itemToEdit?.photoRef"
                    :loading="photoLoading"
                    @click="downloadFile('photo')"
                  >
                    photo<v-icon right>mdi-download</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="pb-0 text-center">
                  <v-file-input
                    v-if="!isViewMode"
                    outlined
                    v-model="cvBlob"
                    accept=".pdf"
                    truncate-length="20"
                    label="CV File"
                    :loading="itemToEdit?.cvRef ? cvLoading : false"
                    :disabled="itemToEdit?.cvRef ? cvLoading : false"
                    @change="processFileRef($event, 'cv')"
                    :rules="
                      isAdmin
                        ? [rules.isPdf]
                        : [rules.requiredFile, rules.isPdf]
                    "
                  />
                  <v-btn
                    v-else
                    x-large
                    width="100%"
                    height="58px"
                    color="accent"
                    class="mb-7"
                    :disabled="!itemToEdit?.cvRef"
                    :loading="cvLoading"
                    @click="downloadFile('cv')"
                  >
                    cv<v-icon right>mdi-download</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- HR DETAILS -->
        <v-tab-item eager>
          <v-container class="d-flex">
            <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-outer-icon="
                  userData.email && !profileView
                    ? 'mdi-email-arrow-right-outline'
                    : null
                "
                label="Email"
                :disabled="!isViewMode && isEditMode"
                v-model="userData.email"
                :rules="[rules.required, rules.validEmail]"
                @click:append-outer="sendEmail(userData.email)"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                :append-outer-icon="
                  userData.personalEmail && !profileView
                    ? 'mdi-email-arrow-right-outline'
                    : null
                "
                label="Personal Email"
                :clearable="!isViewMode"
                v-model="userData.personalEmail"
                :rules="[rules.required, rules.validEmail]"
                @click:append-outer="sendEmail(userData.personalEmail)"
              />
              <v-row class="mt-0">
                <v-col cols="12" sm="6" class="pt-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Country"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    :items="countries"
                    v-model="userData.country"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Phone Number"
                    :clearable="!isViewMode"
                    v-model="userData.phoneNumber"
                    :rules="isAdmin ? [] : [rules.required]"
                  />
                </v-col>
              </v-row>
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Home Address"
                :clearable="!isViewMode"
                v-model="userData.homeAddress"
                :rules="isAdmin ? [] : [rules.required]"
              />
              <v-text-field
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                label="Post Address (Nova Poshta)"
                :clearable="!isViewMode"
                :disabled="userData.country !== 'Ukraine'"
                v-model="userData.postAddress"
                :rules="
                  isAdmin || userData.country !== 'Ukraine'
                    ? []
                    : [rules.required]
                "
              />
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0 text-center">
              <v-row class="my-0 align-center">
                <v-col cols="12" sm="6" class="text-center py-0">
                  <v-checkbox
                    :readonly="isViewMode"
                    class="mt-0 pt-0"
                    prepend-icon="mdi-account-tie"
                    label="Private Entrepreneur"
                    v-model="userData.isPe"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="6" class="text-center py-0">
                  <v-text-field
                    v-if="!isViewMode && isAdmin"
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Documents Link"
                    :clearable="!isViewMode"
                    v-model="userData.docsLink"
                  />
                  <v-btn
                    v-else
                    block
                    x-large
                    height="56px"
                    color="accent"
                    class="docs-link-btn"
                    :disabled="!itemToEdit?.docsLink"
                    :href="itemToEdit?.docsLink"
                    target="_blank"
                  >
                    Documents<v-icon right>mdi-link</v-icon>
                  </v-btn></v-col
                >
              </v-row>
              <v-row class="my-0 align-center">
                <v-col cols="12" sm="6" class="text-center py-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Card for reimbursements/gifts"
                    :clearable="!isViewMode"
                    v-model="userData.card"
                    :rules="isAdmin ? [] : [rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="text-center py-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Emergency contact"
                    :clearable="!isViewMode"
                    v-model="userData.emergency"
                    :rules="isAdmin ? [] : [rules.required]"
                  />
                </v-col>
              </v-row>
              <v-textarea
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                rows="2"
                clear-icon="mdi-close-circle-outline"
                label="Alternative communication via"
                :clearable="!isViewMode"
                v-model="userData.altContacts"
                :rules="isAdmin ? [] : [rules.required]"
              />
              <v-textarea
                :outlined="!isViewMode"
                :filled="isViewMode"
                :shaped="isViewMode"
                :readonly="isViewMode"
                rows="3"
                clear-icon="mdi-close-circle-outline"
                label="Hobbies/fields of interest"
                :clearable="!isViewMode"
                v-model="userData.hobbies"
                :rules="isAdmin ? [] : [rules.required]"
              />
            </v-col>
          </v-container>
        </v-tab-item>
        <!-- ADMIN DETAILS -->
        <v-tab-item v-if="isAdmin" eager>
          <v-container class="d-flex flex-wrap">
            <v-col cols="12" sm="6" class="pb-0">
              <v-row>
                <v-col cols="12" sm="12" class="pb-0">
                  <v-autocomplete
                    multiple
                    small-chips
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    label="Roles"
                    :items="userRoleOptions"
                    v-model="userData.roles"
                    :rules="[rules.requiredArray]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col
                  cols="12"
                  :sm="profileView || !itemToEdit?.access ? 6 : 4"
                  class="d-flex justify-center pb-0"
                >
                  <v-checkbox
                    class="mt-0 pt-0"
                    :readonly="isViewMode"
                    :disabled="!isViewMode && !isAdmin"
                    prepend-icon="mdi-account-key-outline"
                    label="Office Access"
                    v-model="userData.access"
                  ></v-checkbox>
                </v-col>
                <v-col
                  v-if="!profileView && itemToEdit?.user?.access"
                  cols="12"
                  sm="4"
                  class="d-flex justify-center py-0"
                >
                  <v-btn
                    :disabled="
                      !userData.access ||
                      !isAdmin ||
                      !isEditMode ||
                      isViewMode ||
                      !itemToEdit?.access
                    "
                    class="mb-3"
                    :loading="resetLoading"
                    @click="sendPasswordReset"
                  >
                    <v-icon class="mr-2">mdi-lock-reset</v-icon>Reset Pw
                  </v-btn></v-col
                >
                <v-col
                  cols="12"
                  :sm="profileView || !itemToEdit?.access ? 6 : 4"
                  class="d-flex pb-0"
                >
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Slack ID"
                    v-model="userData.slackId"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="userData.startDate"
                        :rules="[rules.required]"
                        :class="{ disabledPicker: isViewMode }"
                        label="Start Date"
                        prepend-icon="mdi-calendar-start"
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        readonly
                        :disabled="!isViewMode && !isAdmin"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="startDateMenu = false"
                      v-model="userData.startDate"
                    ></v-date-picker> </v-menu
                ></v-col>
                <v-col cols="12" sm="6" class="pb-0"
                  ><v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="userData.endDate"
                        :class="{ disabledPicker: isViewMode }"
                        label="End Date"
                        prepend-icon="mdi-calendar-end"
                        :outlined="!isViewMode"
                        :filled="isViewMode"
                        :shaped="isViewMode"
                        readonly
                        :disabled="!isViewMode && !isAdmin"
                        :clearable="!isViewMode"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="endDateMenu = false"
                      v-model="userData.endDate"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" sm="6" class="pb-0">
              <v-row class="my-0 align-center">
                <v-col cols="12" sm="6" class="text-center py-0">
                  <v-text-field
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    type="number"
                    label="Hourly rate"
                    v-model="userData.rate"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="text-center py-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Availability/capacity"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    :items="availabilityOptions"
                    v-model="userData.availability"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="my-0 align-center">
                <v-col cols="12" sm="12" class="text-center pb-0">
                  <v-autocomplete
                    :outlined="!isViewMode"
                    :filled="isViewMode"
                    :shaped="isViewMode"
                    :readonly="isViewMode"
                    label="Specialty"
                    :append-icon="isViewMode ? '' : '$dropdown'"
                    :items="specialties"
                    v-model="userData.specialty"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="my-0 align-center">
                <v-col cols="12" sm="12" class="pb-0 text-center">
                  <v-text-field
                    v-if="!isViewMode"
                    outlined
                    label="Corporate CV Link"
                    clearable
                    v-model="userData.cvLink"
                  />
                  <v-btn
                    v-else
                    x-large
                    width="100%"
                    height="58px"
                    color="accent"
                    class="mb-7"
                    :disabled="!itemToEdit?.cvLink"
                    :href="itemToEdit?.cvLink"
                    target="_blank"
                  >
                    Corporate CV<v-icon right>mdi-link</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="justify-end">
        <v-scale-transition>
          <div v-if="deleteConfirmation" class="d-flex align-center">
            <h3>Are you sure?</h3>
            <v-btn class="ml-2" color="success" @click="deleteStaff">
              Yes
            </v-btn>
            <v-btn
              class="ml-2"
              color="error"
              @click="deleteConfirmation = false"
            >
              No
            </v-btn>
          </div>
        </v-scale-transition>
        <v-scale-transition>
          <v-container
            v-if="closingConfirmation"
            class="d-flex align-center staff-form__close-conf-block px-0 pa-0"
          >
            <h5>Do you want to leave without saving your changes?</h5>
            <v-btn class="ml-2" color="success" @click="closeForm"> Yes </v-btn>
            <v-btn
              class="ml-2"
              color="error"
              @click="closingConfirmation = false"
            >
              No
            </v-btn>
          </v-container>
        </v-scale-transition>
        <div>
          <v-scroll-x-reverse-transition>
            <v-btn
              v-if="!isViewMode && isEditMode && !profileView && isAdmin"
              min-width="unset"
              color="secondary"
              :loading="staffHandling"
              :disabled="deleteConfirmation"
              @click="deleteConfirmation = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-scroll-x-reverse-transition>
          <v-scroll-x-reverse-transition>
            <v-btn
              v-if="!isViewMode && profileView"
              class="ml-2"
              color="secondary"
              :loading="staffHandling"
              :disabled="deleteConfirmation || closingConfirmation"
              @click="handleStaff"
            >
              {{ isEditMode ? 'Update' : 'Save' }}
            </v-btn>
          </v-scroll-x-reverse-transition>
          <v-btn
            v-if="
              isEditMode &&
              ($store.getters['auth/user'].user_id !== itemToEdit.user_id ||
                profileView)
            "
            class="ml-2"
            color="secondary"
            :disabled="deleteConfirmation || closingConfirmation"
            @click="handleModeChange"
          >
            {{ isViewMode ? 'Edit Mode' : 'View Mode' }}
          </v-btn>
          <v-btn
            class="ml-2"
            color="secondary"
            :loading="staffHandling"
            :disabled="deleteConfirmation || closingConfirmation"
            @click="handleClose"
          >
            {{ isViewMode ? 'Close' : 'Cancel' }}
          </v-btn>
        </div></v-card-actions
      >
    </v-form>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions } from 'vuex';
// libraries
import { saveAs } from 'file-saver';
// internal
import {
  processDocument,
  STAFF_COLLECTION_NAME,
  deleteDocument,
  resetPassword,
  getFileBlob,
  uploadFile,
  deleteFile,
} from '@/utils/dbUtils';
import { createNewUserWithPasswordSendEmail } from '@/utils/authUtils';
import defaultAvatar from '@/assets/logo.png';
import { rules } from '@/utils/mixins';

export default {
  name: 'StaffForm',
  props: {
    itemToEdit: {
      type: Object,
      required: true,
    },
    profileView: {
      type: Boolean,
      required: true,
    },
    isOperating: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tab: 0,
      defaultAvatar,
      engLevelOptions: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      bDayMenu: false,
      startDateMenu: false,
      endDateMenu: false,
      resetLoading: false,
      countries: ['Canada', 'Israel', 'Poland', 'Ukraine'],
      avatar: '',
      pronounsList: ['She/Her', 'He/Him', 'They/Them'],
      photoBlob: null,
      photoLoading: false,
      cvBlob: null,
      cvLoading: false,
      userData: {
        access: false,
        email: null,
        firstName: null,
        lastName: null,
        endDate: null,
        slackId: null,
        startDate: null,
        engLevel: null,
        linkedIn: null,
        photoRef: false,
        cvLink: null,
        preferredName: null,
        bday: null,
        pronouns: null,
        website: null,
        country: null,
        personalEmail: null,
        homeAddress: null,
        postAddress: null,
        legalName: null,
        isPe: false,
        card: null,
        phoneNumber: null,
        docsLink: null,
        specialty: null,
        roles: [],
        cvRef: false,
        specialty: null,
        rate: null,
        availability: null,
      },
      availabilityOptions: ['Full-time', 'Part-time'],
      userRoleOptions: ['Admin', 'HR', 'Sales', 'PM', 'SE'],
      specialties: [
        'Front end',
        'Back end',
        'Full stack',
        'Mobile',
        'Project Management',
        'Sales',
        'Design',
        'QA',
      ],
      processFile: false,
      isViewMode: false,
      staffHandling: false,
      deleteConfirmation: false,
      closingConfirmation: false,
      valid: true,
    };
  },
  mounted() {
    this.dataViewsHandler();
  },
  mixins: [rules],
  computed: {
    ...mapGetters('auth', ['isAdmin', 'profileLock']),
    steps() {
      const allSteps = [
        { icon: 'card-account-details-outline', title: 'OVERVIEW' },
        { icon: 'account-circle-outline', title: 'PERSONAL DETAILS' },
        { icon: 'file-account-outline', title: 'HR DETAILS' },
      ];
      if (this.isAdmin) {
        if (!allSteps.includes({ icon: 'security', title: 'ADMIN DETAILS' }))
          allSteps.splice(3, 0, { icon: 'security', title: 'ADMIN DETAILS' });
      } else {
        if (allSteps.includes({ icon: 'security', title: 'ADMIN DETAILS' }))
          allSteps.splice(3, 1);
      }
      return allSteps;
    },
    isEditMode() {
      return !!Object.keys(this.itemToEdit).length;
    },
    fullName: {
      get() {
        return `${this.userData.firstName} ${this.userData.lastName}`;
      },
      set(newVal) {
        if (newVal)
          [this.userData.firstName, this.userData.lastName] = newVal.split(' ');
        else this.userData.firstName = this.userData.lastName = null;
      },
    },
    role() {
      return this.userData.userRole;
    },
  },
  watch: {
    itemToEdit() {
      this.dataViewsHandler();
    },
  },
  methods: {
    ...mapActions('flashMessage', ['handleFlash']),
    async dataViewsHandler() {
      this.deleteConfirmation = false;
      this.userData = { ...this.itemToEdit };
      if (!this.isEditMode) {
        await this.$refs.form.reset();
        this.fullName = '';
        this.userData.cvRef = this.userData.photoRef = false;
      }
      if (this.userData.cvRef) this.handleFile('cv', 'application/pdf');
      else this.cvBlob = null;
      if (this.userData.photoRef) this.handleFile('photo', 'image/jpeg');
      else this.photoBlob = null;
      this.isViewMode = this.isEditMode;
    },
    handleFile(fileName, type) {
      this[`${fileName}Loading`] = true;
      getFileBlob(`${this.itemToEdit.id}_${fileName}File`).then((blob) => {
        let newBlob = new Blob([blob], { type });
        newBlob.name = `${this.fullName}'s ${fileName}`;
        this[`${fileName}Blob`] = newBlob;
        if (fileName === 'photo') {
          const reader = new FileReader();
          reader.onloadend = () => (this.avatar = reader.result);
          reader.readAsDataURL(newBlob);
        }
        this[`${fileName}Loading`] = false;
      });
    },
    downloadFile(fileName) {
      saveAs(this[`${fileName}Blob`], `${this.fullName}'s ${fileName}`);
    },
    sendEmail(email) {
      window.open(`mailto:${email}`);
    },
    processFileRef(file, fileName) {
      this.processFile = true;
      this.userData[`${fileName}Ref`] = !!file;
    },
    async handleStaff() {
      this.staffHandling = true;
      if (this.$refs.form.validate()) {
        if (this.processFile && this.itemToEdit?.id) {
          if (this.userData.cvRef)
            await uploadFile({
              file: this.cvBlob,
              fileName: `${this.itemToEdit.id}_cvFile`,
            });
          else {
            if (this.itemToEdit.cvRef)
              await deleteFile(`${this.itemToEdit.id}_cvFile`);
          }
          if (this.userData.photoRef)
            await uploadFile({
              file: this.photoBlob,
              fileName: `${this.itemToEdit.id}_photoFile`,
            });
          else {
            if (this.itemToEdit.photoRef)
              await deleteFile(`${this.itemToEdit.id}_photoFile`);
          }
        }
        processDocument({
          document: this.userData,
          collectionName: STAFF_COLLECTION_NAME,
        }).then(async (id) => {
          if (!this.itemToEdit?.id) {
            await createNewUserWithPasswordSendEmail({
              email: this.userData.email,
            }).catch((err) => this.handleFlash({ response: err, show: true }));
            if (!!this.cvBlob)
              await uploadFile({
                file: this.cvBlob,
                fileName: `${id}_cvFile`,
              });
            if (!!this.photoBlob)
              await uploadFile({
                file: this.photoBlob,
                fileName: `${id}_photoFile`,
              });
          }
          this.staffHandling = false;
          this.$emit('refresh-data', this.profileView, this.userData);
        });
      } else this.staffHandling = false;
    },
    async deleteStaff() {
      this.deleteConfirmation = false;
      this.staffHandling = true;
      if (this.itemToEdit.cvRef)
        await deleteFile(`${this.itemToEdit.id}_cvFile`);
      if (this.itemToEdit.photoRef)
        await deleteFile(`${this.itemToEdit.id}_photoFile`);
      deleteDocument({
        docId: this.itemToEdit.id,
        collectionName: SOURCING_COLLECTION_NAME,
      }).then((resp) => {
        this.staffHandling = false;
        this.$emit('refresh-data');
        this.handleFlash({ response: resp, show: true });
      });
    },
    sendPasswordReset() {
      this.resetLoading = true;
      resetPassword(this.itemToEdit?.user?.user_id).then((resp) => {
        this.resetLoading = false;
        this.handleFlash({ response: resp, show: true });
      });
    },
    handleClose() {
      this.isAdmin || !this.profileView
        ? this.$emit('close-form')
        : (this.closingConfirmation = true);
    },
    closeForm() {
      this.$emit('close-form');
      this.closingConfirmation = false;
    },
    handleModeChange() {
      if (this.tab === 0 && this.isViewMode) this.tab = 1;
      this.isViewMode = !this.isViewMode;
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-form {
  &__profile {
    &-chip {
      width: 100%;
      height: fit-content;
      justify-content: center;
      line-height: 1rem;
      white-space: normal;
      &s-container {
        width: 35%;
        height: 100%;
      }
      &.v-chip {
        border: 1px ridge grey !important;
      }
    }
    &-avatar {
      &-container {
        width: 50%;
      }
      &-info {
        width: 100%;
      }
    }
    &-actions-container {
      width: 15%;
    }
  }
  &__close-conf-block {
    width: fit-content;
  }
}
.v-badge__badge {
  border-radius: 50%;
  height: 30px;
  min-width: 30px;
}
.v-btn--icon.v-size--x-large .v-icon {
  font-size: 40px;
}
.v-input.disabledPicker {
  pointer-events: none;
}
::v-deep {
  .v-skeleton-loader__image {
    height: 350px;
  }
  .v-label--active {
    pointer-events: none;
  }
  .v-btn.slack-btn .v-btn__content {
    justify-content: start;
  }
  .v-icon.v-icon.v-icon--link {
    pointer-events: all;
    color: rgba(0, 0, 0, 0.54) !important;
  }
  .v-input--selection-controls .v-input__slot {
    width: fit-content;
  }
  .v-tab--active {
    color: #fff !important;
  }
}
.docs-link-btn {
  margin-bottom: 30px;
}
</style>
